export const routes = [
    {
        path: '/customer_types',
        name: 'customer_types.browse',
        component: () => import(/* webpackChunkName: "CustomerTypesBrowse" */ '@/views/app/CustomerTypesBrowse.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/customer_types/create',
        name: 'customer_types.create',
        component: () => import(/* webpackChunkName: "CustomerTypesActions" */ '@/views/app/CustomerTypesActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/customer_types/:id/edit',
        name: 'customer_types.edit',
        component: () => import(/* webpackChunkName: "CustomerTypesActions" */ '@/views/app/CustomerTypesActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/customer_types/:id/delete',
        name: 'customer_types.delete',
        component: () => import(/* webpackChunkName: "CustomerTypesActions" */ '@/views/app/CustomerTypesActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup" }
    },
]